import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {TenantService} from './tenant.service';
import {PricingPlanDetails} from '../models/pricing-plan-details.model';


@Injectable({
  providedIn: 'root'
})
export class StripeConnectionService {

  constructor(
    private http:HttpClient,
    private tenant: TenantService) {


  }

  initStripeConnection(authorizationCode: string): Observable<any> {

    const headers = new HttpHeaders().set('Content-Type', "application/json");

    return this.http.post(environment.api.stripeConnectionUrl, {authorizationCode, tenantId: this.tenant.id}, {headers});
  }

  setupDefaultPricingPlans(monthlyPlanDescription:string, yearlyPlanDescription:string,
                           monthlyPlanPrice:number, yearlyPlanPrice: number,
                           lifetimeAccessPrice:number,
                           teamPlanThreshold:number,
                           teamPlanDiscount:number): Observable<PricingPlanDetails> {


    return this.http.post<PricingPlanDetails>(environment.api.stripeInitPricingPlansUrl,
      {
        monthlyPlanDescription,
        yearlyPlanDescription,
        monthlyPlanPrice,
        yearlyPlanPrice,
        lifetimeAccessPrice,
        teamPlanThreshold,
        teamPlanDiscount,
        tenantId: this.tenant?.id,
      });
  }
}
